.anchor {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  &:hover {
    color: var(--color-text-default);
    path {
      fill: var(--color-primary-medium);
    }
  }
}
