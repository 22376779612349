@use "src/styles/utility";

.listItemsContainer {
  padding-inline-start: var(--spacing-medium);
  padding-inline-end: var(--spacing-medium);
  padding-block-end: calc(5 * var(--spacing-mega));
}

.subtitle {
  @include utility.center-vertically;
  font-size: var(--font-size-xsmall);
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  border-block-end: 1px var(--color-borders-hairline) solid;
  min-height: calc(var(--spacing-mega) + var(--spacing-small));
}
