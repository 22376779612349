/* Child */

.top-menu ul {
  list-style-type: none;
  margin: 0;
  position: absolute;
  padding: 0;
}

.top-menu > ul {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.top-menu > ul > li {
  padding: 0 1rem;
  line-height: 3;
  height: 100%;
  display: flex;
}

.top-menu > ul > li:hover a {
  color: var(--color-success-medium);
}

.top-menu > ul > li a {
  color: var(--color-text-default);
}

.top-menu > ul > li.has-child > a::after {
  content: "";
  margin-left: 0.255em;
  border-top: 0.4em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

/* Sub Child */

.top-menu > ul ul {
  display: none;
  padding: 0.5rem 0;
  background-color: var(--color-background-elevated);
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 8%), 0 0 15px 0 rgb(0 0 0 / 2%),
    0 0 20px 4px rgb(0 0 0 / 6%);
  border-radius: 2px;
}

.top-menu > ul ul a {
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.top-menu > ul ul li a[href] {
  color: var(--color-text-default);
}

.top-menu > ul ul li.has-child > a::after {
  content: "";
  margin-left: 0.5rem;
  border-width: 0.3em 0 0.3em 0.4em;
  border-color: transparent transparent transparent var(--color-text-default);
  border-style: solid;
}

.top-menu > ul ul li {
  line-height: 1.2;
}

.top-menu > ul ul li:hover {
  background-color: var(--color-success-medium);
}

.top-menu > ul ul li:hover > a {
  color: #fff;
}

.top-menu > ul > li > ul {
  top: 100%;
  margin-left: -1rem;
}

:is(.top-menu, .side-menu) .icon-wrapper {
  line-height: 0;
}

.top-menu li svg {
  margin-right: 0.3rem;
  width: 1em;
  height: 1em;
}

.top-menu ul li a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.top-menu > ul ul ul {
  left: 100%;
  bottom: 0;
  width: 100%;
}

li.has-child:hover > ul {
  display: block;
}

/* Sidebar Menu */

.side-menu svg {
  width: 1em;
  height: 1em;
  fill: var(--color-secondary-medium);
}

/* Drawer icon show hide based on media query */

@media (min-width: 1150px) {
  [class*="NavbarBody_leftCTA"] button {
    display: none;
  }
}

@media (max-width: 1149px) {
  .top-menu {
    display: none;
  }
}

@media (min-width: 768px) {
  /* Make space between each word in traslation mode */
  [class*="TranslationViewCell_contentContainer"]
    [role="button"][class*="QuranWord_container"] {
    margin-bottom: 1rem;
    margin-right: 0.3rem;
  }

  /* Line and space between lines inside Reading mode */
  [class*="Line_container"] [class*="Line_line"] {
    padding: 0.6rem 0;
    border-bottom: 1px solid;
  }
}

/* word spacing in Reading mode */
[class*="Line_container"] [role="button"][class*="QuranWord_container"] {
  margin-right: 0.5rem;
}
